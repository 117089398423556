import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { SnackbarProvider } from 'notistack';

import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Base from './Base';

import config from './config';

import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import 'react-virtualized/styles.css';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import './assets/css/global-styles.css';

import createStore from './datastore/createStore';

const { store, persistor } = createStore();

firebase.initializeApp(config.firebase);

const theme: Theme = createMuiTheme({
  'overrides': {
    'MuiToolbar': {
      'root': {
        'display': 'flex',
      },
    },
  },
  'palette': {
    'background': {
      'default': '#FAFAFA',
    },
  },
});

const App: React.FC = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider maxSnack={1} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'right' }}>
          <Provider store={store}>
            <ReactReduxFirebaseProvider
              firebase={firebase}
              dispatch={store.dispatch}
              config={{ 'enableLogging': false }}
            >
              <PersistGate persistor={persistor}>
                <BrowserRouter>
                  <Base />
                </BrowserRouter>
              </PersistGate>
            </ReactReduxFirebaseProvider>
          </Provider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.Fragment>
);

export default App;
