module.exports = {
  'firebase': {
    'apiKey': 'AIzaSyBHkYEKSmOdUR9qT0kVsudOiK_ZP0VQWjQ',
    'authDomain': 'swish-application.firebaseapp.com',
    'databaseURL': 'https://swish-application.firebaseio.com',
    'projectId': 'swish-application',
    'storageBucket': 'swish-application.appspot.com',
    'messagingSenderId': '254313100031',
    'appId': '1:254313100031:web:63505f4a0dc1ea465452ca',
    'measurementId': 'G-F58QHTG2WN',
  },
};
