import React, { lazy, Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid'

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const LoginPage = lazy(() => import('./containers/authentication/login'));
const LogoutPage = lazy(() => import('./containers/authentication/logout'));

const HomePage = lazy(() => import('./containers/home'));

const NotFound = lazy(() => import('./containers/not-found'));

const Header = require('./assets/images/header.png');
const Footer = require('./assets/images/footer.png');

const useStyles = makeStyles((theme) => ({

  'main': {
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      'padding': '8px 0',
    },
    [theme.breakpoints.up('sm')]: {
      'padding': '8px 0',
    },
  },

}));

const Base: React.FC = () => {

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.main}>
      <Grid container spacing={1} justify={'center'}>
        <Grid item md={8} sm={12}>
          <img src={Header} alt={'header'} style={{ 'margin': 'auto', 'display': 'block', 'width': isMobile ? '100%' : '50%', 'padding': isMobile ? '4px' : 'inherit' }} />
        </Grid>
        <Grid item md={8} sm={12}>
          <Suspense fallback={<div />}>
            <Switch>

              <Route path={'/auth/login'} exact={true} component={LoginPage} />
              <Route path={'/auth/logout'} exact={true} component={LogoutPage} />

              <Route path={'/'} component={HomePage} />

              <Route component={NotFound} />

            </Switch>
          </Suspense>
        </Grid>
      </Grid>
      <Grid container spacing={1} justify={'center'} style={{ 'position': 'fixed', 'bottom': '0', 'left': '0' }}>
        <Grid item md={8} sm={12}>
          <a href={'https://swish.ca'} rel={'noopener noreferrer'} target={'_blank'}>
            <img src={Footer} alt={'footer'} style={{ 'margin': 'auto', 'display': 'block', 'width': isMobile ? '100%' : '50%', 'padding': isMobile ? '24px' : '16px' }} />
          </a>
        </Grid>
      </Grid>
    </div>
  );

};

export default Base;
